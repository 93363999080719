<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-start flex-wrap mb-3"
    >
      <!-- CARD HEADER -->
      <div class="d-flex align-content-center justify-content-between gap-4">
        <h4>{{ sectionHeader }}</h4>
        <HelpCenterButton :content-key="sectionContentKey" />
      </div>
      <!-- CARD HEADER -->

      <!-- CARD CALL TO ACTION -->
      <BaseButton
        solid
        rounded
        prepend-icon="ri-add-line"
        @click="onAddPreApprovedList()"
      >
        {{ buttonText }}
      </BaseButton>
      <!-- CARD CALL TO ACTION -->
    </div>

    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- PAGE SELECTOR -->
    <Perpage
      v-if="!isLoading && !isSchoolSettingsLoading"
      use-first-option-as-default
      use-dynamic-update
      :options="pageOptions"
      :pagination="pagination"
      @on-page-change="setPage"
    />
    <!-- PAGE SELECTOR -->

    <!-- TABLE -->
    <DataTable
      :class="{ 'visitor-loading': isLoading || isSchoolSettingsLoading }"
      :fields="fields"
      :items="list"
    >
      <template #first_name="{ item }">
        <td>
          {{ item.first_name }}
        </td>
      </template>
      <template #last_name="{ item }">
        <td>
          {{ item.last_name }}
        </td>
      </template>
      <template #date_of_birth="{ item }">
        <td>
          {{ formatDate(item.date_of_birth) }}
        </td>
      </template>
      <template #action="{ item }">
        <td class="text-end">
          <Actions
            v-if="!isLoading && !isSchoolSettingsLoading"
            :actions="actions"
            @click-action="handleTableAction($event, item.id)"
          />
        </td>
      </template>
    </DataTable>
    <!-- TABLE -->

    <!-- PAGINATION -->
    <Pagination
      v-if="pagination.pages > 1 && !isLoading && !isSchoolSettingsLoading"
      :active-page="pagination.activePage"
      :pages="pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    ></Pagination>
    <!-- PAGINATION -->
  </div>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import Perpage from "@/v3components/shared/DataTable/Perpage.vue"
import visitorConstants from "@/constants/visitorConstants"
import passHelpers from "@/helpers/index"
import { computed, defineAsyncComponent, inject, onMounted, ref } from "vue"
import { useStore } from "vuex"

const VisitorCreatePreApprovedListForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/ListSettings/VisitorCreatePreApprovedListForm.vue"
    )
)

export default {
  name: "VisitorPreApprovedListTable",
  components: {
    BaseButton,
    DataTable,
    Actions,
    Perpage,
    Pagination,
    VisitorErrorHandler,
    HelpCenterButton
  },
  props: {
    isSchoolSettingsLoading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const modal = inject("modal")
    const actionDialog = inject("actionDialog")
    const store = useStore()
    const serverError = ref(null)

    const pageOptions = [
      { label: "25 / Page", value: 25 },
      { label: "50 / Page", value: 50 },
      { label: "100 / Page", value: 100 }
    ]

    const fields = [
      { key: "first_name", label: "First name", sorter: false, filter: false },
      { key: "last_name", label: "Last name", sorter: false, filter: false },
      {
        key: "date_of_birth",
        label: "Date of Birth",
        sorter: false,
        filter: false
      },
      {
        key: "action",
        label: "Action",
        _style: "width: 50px;",
        sorter: false,
        filter: false
      }
    ]

    const actions = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const preApprovedList = computed(
      () => store.getters["visitorSettings/preApprovedList"]
    )

    const list = computed(() => preApprovedList?.value?.data || [])

    const pagination = computed(() => preApprovedList?.value?.pagination || {})

    const isLoading = computed(() => preApprovedList?.value?.isLoading || false)

    const setPage = async (option) => {
      if (!option) return

      store.commit(
        "visitorSettings/SET_PRE_APPROVED_LIST_PAGINATION_PER_PAGE",
        option
      )
      try {
        serverError.value = null
        await store.dispatch("visitorSettings/getPreApprovedList")
      } catch (error) {
        serverError.value = error
      }
    }

    const setActivePage = async (page) => {
      if (!page) return

      store.commit(
        "visitorSettings/SET_PRE_APPROVED_LIST_PAGINATION_ACTIVE_PAGE",
        page
      )
      try {
        serverError.value = null
        await store.dispatch("visitorSettings/getPreApprovedList")
      } catch (error) {
        serverError.value = error
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deleteItem, {
          args: item,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
    }

    const deleteItem = async (adultId) => {
      try {
        serverError.value = null
        await store.dispatch("visitorSettings/deletePreApprovedList", adultId)
        await store.dispatch("visitorSettings/getPreApprovedList")
      } catch (error) {
        serverError.value = error
      }
    }

    const onAddPreApprovedList = () => {
      modal.open(VisitorCreatePreApprovedListForm, {
        size: "sm",
        title: visitorConstants.LIST_SETTINGS.PRE_APPROVED_VISITOR_MODAL_TITLE,
        props: {}
      })
    }

    const formatDate = (date) => {
      return passHelpers.getFormattedDateWithoutTimezone(date)
    }

    onMounted(async () => {
      try {
        await store.dispatch("visitorSettings/getPreApprovedList")
      } catch (error) {
        serverError.value = error
      }
    })

    return {
      isLoading,
      list,
      buttonText:
        visitorConstants.LIST_SETTINGS.PRE_APPROVED_VISITOR_ADD_BUTTON,
      sectionHeader:
        visitorConstants.LIST_SETTINGS.PRE_APPROVED_VISITOR_SECTION_HEADER,
      sectionContentKey:
        visitorConstants.LIST_SETTINGS.PRE_APPROVED_VISITOR_LEARN_MORE,
      serverError,
      pageOptions,
      pagination,
      fields,
      actions,
      handleTableAction,
      formatDate,
      setPage,
      setActivePage,
      onAddPreApprovedList
    }
  }
}
</script>

<style scoped></style>
