<template>
  <div class="d-flex flex-wrap gap-1" :class="statusClasses">
    <div
      class="d-flex flex-wrap justify-content-center align-content-center gap-2 visitor-status-pill"
    >
      <i
        class="visitor-status-icon"
        :class="{
          'signed-in ri-check-line':
            visitor?.visit_status?.toLowerCase() === 'signed in',
          'signed-out ri-check-line':
            visitor.visit_status?.toLowerCase() === 'signed out',
          'denied ri-close-line':
            visitor.visit_status?.toLowerCase() === 'denied'
        }"
      ></i>
      <span class="mt-3px text-capitalize">
        {{ visitor.visit_status }}
      </span>
    </div>
    <i
      v-if="statusTooltip.length > 0"
      :title="statusTooltip"
      class="m-auto"
      :class="{
        'ri-information-line': !isBadgePrintingFailed,
        'ri-printer-line visitor-status-red': isBadgePrintingFailed
      }"
    ></i>
  </div>
</template>

<script>
import { computed } from "vue"
export default {
  name: "VisitorStatusColumn",
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const statusClasses = computed(() => {
      const classMap = {
        denied: "visitor-status-red",
        "signed in": "visitor-status-green",
        "signed out": "visitor-status-blue"
      }
      const className =
        classMap?.[props?.visitor?.visit_status?.toLowerCase()] ||
        "visitor-status-default"
      return className
    })

    const isBadgePrintingFailed = computed(() => {
      return (
        props?.visitor?.signin_exception_message
          ?.toString()
          ?.trim()
          ?.toLowerCase() === "app_badge_printing_failed"
      )
    })

    const statusTooltip = computed(() => {
      if (isBadgePrintingFailed.value) {
        return "Badge printing failed"
      }

      let description, matchType
      switch (props?.visitor?.match_type?.trim()?.toLowerCase()) {
        case "offender":
          matchType = "Offender"
          break
        case "watchlist":
          matchType = "Watchlist"
          break
        case "pre-approved-visitor":
          matchType = "Pre-set visitor"
          break
        case "offender/non-guardian":
          matchType = "Offender/Non-guardian"
          break
        case "watchlist/non-guardian":
          matchType = "Watchlist/Non-guardian"
          break
        case "pre-approved-visitor/watchlist":
          matchType = "Pre-set/Watchlist"
          break
        case "pre-approved-visitor/offender":
          matchType = "Pre-set/Offender"
          break
        case "pre-approved-visitor/watchlist/non-guardian":
          matchType = "Pre-set/Watchlist/Non-guardian"
          break
        case "pre-approved-visitor/offender/non-guardian":
          matchType = "Pre-set/Offender/Non-guardian"
          break
        default:
          matchType = "Visitor"
      }
      if (props?.visitor?.visit_status?.trim()?.toLowerCase() === "denied") {
        description = "Denial reason:"
      } else if (props?.visitor?.health_check === "Fail") {
        description = "Health check exception reason:"
      } else {
        description = `${matchType} exception reason:`
      }

      const message = props?.visitor?.signin_exception_message || ""

      if (
        matchType &&
        props?.visitor?.health_check?.trim()?.toLowerCase() === "fail" &&
        props?.visitor?.signin_exception_message?.indexOf("/") !== -1
      ) {
        description = `${matchType}/Health check exception reason:`
      }

      if (message?.length) {
        return `${description} ${message}`
      }

      return ""
    })

    return {
      statusClasses,
      statusTooltip,
      isBadgePrintingFailed
    }
  }
}
</script>

<style scoped>
.mt-3px {
  margin-top: 3px;
}
</style>
