<template>
  <div
    class="d-flex flex-column gap-4"
    :class="{
      'visitor-loading': isLoading
    }"
  >
    <!-- HEADER -->
    <div class="d-flex justify-content-between align-items-start flex-wrap">
      <h4>Visitor Adults</h4>
    </div>
    <!-- HEADER -->

    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- FILTERS -->
    <CRow class="gap-4">
      <!-- SEARCH ADULTS -->
      <CCol md class="d-flex flex-column gap-2">
        <InputField
          prepend-icon="ri-search-line"
          id="search"
          placeholder="Search adults..."
          v-model="search"
        />
      </CCol>
      <!-- SEARCH ADULTS -->

      <!-- PAGE SELECTOR -->
      <CCol md class="d-flex flex-column gap-2 justify-content-end">
        <Perpage
          use-first-option-as-default
          use-dynamic-update
          :options="pageOptions"
          :pagination="pagination"
          @on-page-change="setPage"
        />
      </CCol>
      <!-- PAGE SELECTOR -->
    </CRow>
    <!-- FILTERS -->

    <!-- TABLE -->
    <DataTable :fields="fields" :items="list">
      <template #first_name="{ item }">
        <td>
          {{ item.first_name }}
        </td>
      </template>
      <template #last_name="{ item }">
        <td>
          {{ item.last_name }}
        </td>
      </template>
      <template #email_address="{ item }">
        <td>
          {{ item.email }}
        </td>
      </template>

      <template #action="{ item }">
        <td class="text-end">
          <Actions
            :actions="actions"
            :class="{
              'visitor-loading': isLoading
            }"
            @click-action="handleTableAction($event, item)"
          >
          </Actions>
        </td>
      </template>
    </DataTable>
    <!-- TABLE -->

    <!-- PAGINATION -->
    <Pagination
      v-if="pagination.pages > 1 && !isLoading"
      :active-page="pagination.activePage"
      :pages="pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    ></Pagination>
    <!-- PAGINATION -->
  </div>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import Perpage from "@/v3components/shared/DataTable/Perpage.vue"
import { debounce } from "lodash"
import { computed, defineAsyncComponent, inject, ref, onUnmounted } from "vue"
import { useStore } from "vuex"

const VisitorEditAdultForm = defineAsyncComponent(
  () => import("@/v3components/Forms/VisitorEditAdultForm.vue")
)

export default {
  name: "VisitorAdultsTable",
  components: {
    InputField,
    DataTable,
    Actions,
    Perpage,
    Pagination,
    VisitorErrorHandler
  },
  setup() {
    const modal = inject("modal")
    const actionDialog = inject("actionDialog")
    const store = useStore()

    const isLoading = ref(false)
    const serverError = ref(null)

    const pageOptions = [
      { label: "25 / Page", value: 25 },
      { label: "50 / Page", value: 50 },
      { label: "100 / Page", value: 100 }
    ]

    const commonFields = [
      { key: "first_name", label: "First name", sorter: false, filter: false },
      { key: "last_name", label: "Last name", sorter: false, filter: false },
      {
        key: "email_address",
        label: "Email address",
        sorter: false,
        filter: false
      }
    ]

    const commonFieldsWithActionColumn = [
      ...commonFields,
      {
        key: "action",
        label: "Action",
        _style: "width: 50px;",
        sorter: false,
        filter: false
      }
    ]

    const fields = computed(() => {
      return areAllTeachersStaffAllowed.value
        ? commonFields
        : commonFieldsWithActionColumn
    })

    const actions = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete",
        show: true
      },
      {
        label: "Edit",
        icon: "ri-pencil-line",
        class: "primary",
        action: "edit",
        show: false
      }
    ].filter((action) => action.show)

    const adults = computed(() => store.getters["visitorSettings/adults"])

    const list = computed(() => adults?.value?.users || [])

    const areAllTeachersStaffAllowed = computed(
      () => store.getters["visitorSettings/areAllTeachersStaffAllowed"]
    )

    const pagination = computed(() => adults?.value?.pagination || {})

    const search = computed({
      get: () => adults.value?.search || "",
      set: debounce(async (value) => {
        store.commit("visitorSettings/SET_ADULTS_SEARCH", value)
        try {
          serverError.value = null
          isLoading.value = true
          await store.dispatch("visitorSettings/getAdults")
        } catch (error) {
          serverError.value = error
        } finally {
          isLoading.value = false
        }
      }, 500)
    })

    const setPage = async (option) => {
      if (!option) return
      store.commit("visitorSettings/SET_ADULTS_PAGINATION_PER_PAGE", option)
      try {
        serverError.value = null
        isLoading.value = true
        await store.dispatch("visitorSettings/getAdults")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    const setActivePage = async (page) => {
      if (!page) return
      store.commit("visitorSettings/SET_ADULTS_PAGINATION_ACTIVE_PAGE", page)
      try {
        serverError.value = null
        isLoading.value = true
        await store.dispatch("visitorSettings/getAdults")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deleteItem, {
          args: item.frontdesk_id,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
      if (event.action == "edit") {
        modal.open(VisitorEditAdultForm, {
          size: "sm",
          title: "Edit adult",
          props: { editUser: item }
        })
      }
    }

    const deleteItem = async (adultId) => {
      try {
        isLoading.value = true
        serverError.value = null
        await store.dispatch("visitorSettings/deleteAdult", adultId)
        await store.dispatch("visitorSettings/getAdults")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    const onAddAdults = () => {
      modal.open(VisitorEditAdultForm, {
        size: "sm",
        title: "Add to adults",
        props: {}
      })
    }

    onUnmounted(() => {
      store.commit("visitorSettings/RESET_ADULTS_STATE")
    })

    return {
      isLoading,
      serverError,
      areAllTeachersStaffAllowed,
      search,
      list,
      pageOptions,
      pagination,
      fields,
      actions,
      handleTableAction,
      setPage,
      setActivePage,
      onAddAdults
    }
  }
}
</script>

<style scoped></style>
