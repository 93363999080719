<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-start flex-wrap mb-3"
    >
      <!-- CARD HEADER -->
      <div class="d-flex align-content-center justify-content-between gap-4">
        <h4>{{ sectionHeader }}</h4>
        <HelpCenterButton :content-key="sectionContentKey" />
      </div>
      <!-- CARD HEADER -->

      <!-- CARD CALL TO ACTION -->
      <BaseButton
        solid
        rounded
        prepend-icon="ri-add-line"
        @click="onAddEmergencyContact()"
      >
        {{ buttonText }}
      </BaseButton>
      <!-- CARD CALL TO ACTION -->
    </div>

    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- PAGE SELECTOR -->
    <Perpage
      v-if="!isLoading && !isSchoolSettingsLoading"
      use-first-option-as-default
      use-dynamic-update
      :options="pageOptions"
      :pagination="pagination"
      @on-page-change="setPage"
    />
    <!-- PAGE SELECTOR -->

    <!-- TABLE -->
    <DataTable
      :class="{ 'visitor-loading': isLoading || isSchoolSettingsLoading }"
      :fields="fields"
      :items="paginatedList"
    >
      <template #first_name="{ item }">
        <td>
          {{ item.first_name }}
        </td>
      </template>
      <template #last_name="{ item }">
        <td>
          {{ item.last_name }}
        </td>
      </template>
      <template #email_address="{ item }">
        <td>
          {{ item.email_address?.join(", ") }}
        </td>
      </template>
      <template #phone_number="{ item }">
        <td>
          {{ item.phone_number?.join(", ") }}
        </td>
      </template>
      <template #action="{ item }">
        <td class="text-end">
          <Actions
            v-if="!isLoading && !isSchoolSettingsLoading"
            :actions="actions"
            @click-action="handleTableAction($event, item.id)"
          >
          </Actions>
        </td>
      </template>
    </DataTable>
    <!-- TABLE -->

    <!-- PAGINATION -->
    <Pagination
      v-if="pagination.pages > 1 && !isLoading && !isSchoolSettingsLoading"
      :active-page="pagination.activePage"
      :pages="pagination.pages"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      size="lg"
      align="center"
      @update:active-page="setActivePage"
    ></Pagination>
    <!-- PAGINATION -->
  </div>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Perpage from "@/v3components/shared/DataTable/Perpage.vue"
import visitorConstants from "@/constants/visitorConstants"
import {
  computed,
  defineAsyncComponent,
  inject,
  reactive,
  ref,
  watchEffect
} from "vue"
import { useStore } from "vuex"

const VisitorCreateEmergencyContactForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/ListSettings/VisitorCreateEmergencyContactForm.vue"
    )
)

export default {
  name: "VisitorEmergencyContactTable",
  components: {
    DataTable,
    Actions,
    Perpage,
    BaseButton,
    VisitorErrorHandler,
    HelpCenterButton
  },
  props: {
    isSchoolSettingsLoading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const actionDialog = inject("actionDialog")
    const modal = inject("modal")
    const store = useStore()

    const isLoading = ref(false)
    const serverError = ref(null)

    const pageOptions = [
      { label: "25 / Page", value: 25 },
      { label: "50 / Page", value: 50 },
      { label: "100 / Page", value: 100 }
    ]

    const fields = [
      { key: "first_name", label: "First name", sorter: false, filter: false },
      { key: "last_name", label: "Last name", sorter: false, filter: false },
      {
        key: "email_address",
        label: "Email Address",
        sorter: false,
        filter: false
      },
      {
        key: "phone_number",
        label: "Phone Number",
        sorter: false,
        filter: false
      },
      {
        key: "action",
        label: "Action",
        _style: "width: 50px;",
        sorter: false,
        filter: false
      }
    ]

    const actions = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const pagination = reactive({
      activePage: 1,
      total: 0,
      pages: 0,
      per_page: { label: "25 / Page", value: 25 }
    })

    const school = computed(() => store.getters["visitorSettings/school"])

    const list = computed(() => school?.value?.data?.emergency_staffs || [])

    const paginatedList = computed(() => {
      const start = (pagination.activePage - 1) * pagination.per_page.value
      const end = start + pagination.per_page.value
      return list.value.slice(start, end)
    })

    const setPage = (option) => {
      if (!option) return
      pagination.per_page = option
      pagination.activePage = 1
    }

    const setActivePage = (page) => {
      if (!page) return
      pagination.activePage = page
    }

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deleteItem, {
          args: item,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
    }

    const deleteItem = async (adultId) => {
      try {
        isLoading.value = true
        await store.dispatch("visitorSettings/deleteEmergencyContact", adultId)
        setPage({
          label: "25 / Page",
          value: 25
        })
        await store.dispatch("visitorSettings/getSchoolSettings")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    const onAddEmergencyContact = () => {
      modal.open(VisitorCreateEmergencyContactForm, {
        size: "sm",
        title: visitorConstants.LIST_SETTINGS.EMERGENCY_CONTACT_MODAL_TITLE,
        props: {}
      })
    }

    watchEffect(() => {
      if (school.value) {
        pagination.total = list.value.length
        pagination.pages = Math.ceil(
          list.value.length / pagination.per_page.value
        )
      }
    })

    return {
      isLoading,
      serverError,
      buttonText: visitorConstants.LIST_SETTINGS.EMERGENCY_CONTACT_ADD_BUTTON,
      sectionHeader:
        visitorConstants.LIST_SETTINGS.EMERGENCY_CONTACT_SECTION_HEADER,
      sectionContentKey:
        visitorConstants.LIST_SETTINGS.EMERGENCY_CONTACT_LEARN_MORE,
      paginatedList,
      pageOptions,
      pagination,
      fields,
      actions,
      handleTableAction,
      setPage,
      setActivePage,
      onAddEmergencyContact
    }
  }
}
</script>
